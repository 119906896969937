import React, { Component } from "react";
import styled from "styled-components";
import { withTheme } from "styled-components";
import Header from "../components/Header/Header";
import HeaderLeftLinks from "../components/Header/HeaderLeftLinks";
import HeaderLinks from "../components/Header/HeaderLinks";
import { Color as Colors } from "../src/utils/Color";
import Footer from "../components/Footer/Footer";
import Container from "@material-ui/core/Container";
import Typography from "@mui/material/Typography";
import Grid from "@material-ui/core/Grid";
import FacebookIcon from "@mui/icons-material/Facebook";
import GoogleIcon from "@mui/icons-material/Google";
import Button from "@material-ui/core/Button";
import Divider from "@mui/material/Divider";
import CustomFont from "../components/CustomFont";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Router from "next/router";
import { maxDevice } from "../src/utils/breakpoints";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  FacebookAuthProvider,
  sendPasswordResetEmail
} from "firebase/auth";
import * as selectors from "../src/selectors";
import * as globalActions from "../src/actions/global";
import { connect } from "dva";
import * as authActions from "../src/actions/auth";
import LoadingOverlay from "../components/LoadingOverlay";
import Toast from "../src/utils/Toast";
import Constants from "../src/constants";
import Helpers from "../src/utils/Helpers";
import * as profileSelectors from "../src/selectors/profile";
import Cookies from "js-cookie";
import _ from "lodash";
import { NextSeo } from "next-seo";
import Image from "next/image";
import TagManager from 'react-gtm-module';
import { getProfileID } from '../src/selectors/profile'
const provider = new GoogleAuthProvider();
const ariaLabel = { "aria-label": "description" };


const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const StyledLoginContainer = styled.div`
  box-shadow: 1px 1px 5px #aaaaaa;
  background: white;
  border-radius: 10px;
  padding: 40px 60px;
  margin-top: 3rem;
  margin-bottom: 6rem;
  position: relative;

  @media ${maxDevice.sm} {
    padding: 30px 30px;
  }
`;

const StyledLoginButtonContainer = styled(Button)`
  width: 100%;
`;

const StyledLoginEmailButtonContainer = styled(Button)`
  width: 100%;

  @media ${maxDevice.sm} {
    margin-top: 40px !important;
  }
`;

const StyledInputContainer = styled(Input)`
  width: 100%;
  padding: 10px 20px;
`;

const StyledForgotPasswordContainer = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 10px;
`;

const StyledForgotPasswordItemContainer = styled.div`
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`;
const SignUpLogoContainer = styled.div`
  position: relative;
  width: 100%;
  height: 95px;
  margin: 5px 0;
  @media ${maxDevice.md} {
    height: 80px;
  }
`;
const SignUpTitleContainer = styled.div`
  & h1 {
    font-size: 1.5rem!important;
  }
  @media ${maxDevice.md} {
    & h1 {
      font-size: 1.3rem!important;
    }
  }
`;
const SignUpSubContainer = styled.div`
  & h1 {
    font-size: 0.9rem!important;
  } 
  & p {
    font-size: 1rem!important;
  }
  @media ${maxDevice.md} {
    & h1 {
      font-size: 0.8rem!important;
    }    
    & p {
      font-size: 0.9rem!important;
    } 
  }
`;

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      passwordVisible: false,
      googleLoginLoading: false,
      loginProvider: "",
      email: "",
      password: "",
      emailLoading: false,
      facebookLoginLoading: false
    };
  }

  componentDidMount() {
    if (!_.isEmpty(this.props.authToken)) {
      Router.push("/profile");
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.authFirebaseStatus !== this.props.authFirebaseStatus) {
      if (this.props.authFirebaseStatus) {
        this.handleSignIn();
      }
    }
    if (this.props.signInStatus !== prevProps.signInStatus) {
      if (this.props.signInStatus) {
        this.handleSignIn();
      }
    }
  }

  handleSignIn = () => {
    const { meData } = this.props;
    const isAgent = !_.isEmpty(profileSelectors.getProfileAgent(meData));
    const id = profileSelectors.getProfileID(meData);
    const cookiesUrl = Cookies.get(Constants.COOKIES_REDIRECT_URL);
    const { method } = this.state;
      const prevUrlNapic = sessionStorage.getItem('prevUrlNapic');
    let role = isAgent ? 'Agent' : 'User';

    window.gtag('event', 'login', {
      method: method,
      user_id: id,
      role_type: role
    });
    // if (!_.isEmpty(cookiesUrl)) {
    //   const splitCookiesUrl = cookiesUrl.split("?");
    //   const searchParam = splitCookiesUrl[1];
    //   let param = null;
    //   if (!_.isEmpty(splitCookiesUrl[1])) {
    //     param = JSON.parse(
    //       '{"' + searchParam.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
    //       function(key, value) {
    //         return key === "" ? value : decodeURIComponent(value);
    //       }
    //     );
    //   }
    //   if (!_.isEmpty(param)) {
    //     Router.push({
    //       pathname: splitCookiesUrl[0],
    //       query: param
    //     });
    //   } else {
    //     Router.push({
    //       pathname: splitCookiesUrl[0]
    //     });
    //   }
    //   return;
    // }
    // if (isAgent) {
    //   Router.push("/agent");
    // } else {
    //   Router.push("/profile");
    // }
      if (prevUrlNapic) {
          // sessionStorage.removeItem('prevUrlNapic');
          // Router.push(prevUrlNapic);
          if (Router.asPath !== '/login') {
              sessionStorage.removeItem('prevUrlNapic');
          } else {
              // Redirect to the previous URL
              Router.push(prevUrlNapic);
              sessionStorage.removeItem('prevUrlNapic');
          }
      } else {
          if (isAgent) {
              Router.push("/agent");
          } else {
              Router.push("/profile");
          }
      }
      // setTimeout(() => {
      //     sessionStorage.removeItem('prevUrlNapic');
      // }, 120000);
  };

  handlePasswordVisible = () => {
    this.setState({
      passwordVisible: !this.state.passwordVisible
    });
  };

  onClickSignUp = e => {
    e.preventDefault();
      sessionStorage.setItem('prevUrlLogin', window.location.pathname);
    Router.push("/sign-up-form");
  };

  onClickLoginWithGoogle = () => {
    const { postAuthFirebase } = this.props;
    this.setState({
      googleLoginLoading: true,
      loginProvider: Constants.LOGIN_PROVIDER_GOOGLE
    });
    const auth = getAuth();
    signInWithPopup(auth, provider)
      .then(async result => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        const idToken = await user.getIdToken(true);
        if (!_.isEmpty(idToken)) {
          postAuthFirebase && postAuthFirebase({ idToken: idToken });
          this.setState({ method: 'Google' });
        }
        this.setState({ googleLoginLoading: false });

        // ...
      })
      .catch(error => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        Toast.error(Helpers.firebaseSignupErrorCode(errorCode));
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        this.setState({ googleLoginLoading: false });

        // ...
      });
  };

  onChangePasswordText = e => {
    this.setState({ password: e.target.value });
  };

  onChangeEmailText = e => {
    this.setState({
      email: e.target.value
    });
  };

  onClickLoginWithEmail = e => {
    e.preventDefault();

    const { email, password } = this.state;

    const { postAuthFirebase, postAuthSignIn } = this.props;

    if (_.isEmpty(email)) {
      Toast.error("Please enter your phone number");
      return;
    }

    if (_.isEmpty(password)) {
      Toast.error("Please enter your password");
      return;
    }
    this.setState({ method: 'Normal' });
    postAuthSignIn &&
      postAuthSignIn({
        username: email,
        password: password
      });
    // this.setState({ isModalVisible: false });
    // Router.push("/profile");
  };

  signInWithFacebook = () => {
    const { postAuthFirebase } = this.props;

    const provider = new FacebookAuthProvider();
    const auth = getAuth();
    this.setState({
      facebookLoginLoading: true,
      loginProvider: Constants.LOGIN_PROVIDER_FACEBOOK
    });
    signInWithPopup(auth, provider)
      .then(async result => {
        // The signed-in user info.
        const user = result.user;

        // This gives you a Facebook Access Token. You can use it to access the Facebook API.
        const credential = FacebookAuthProvider.credentialFromResult(result);
        const accessToken = credential.accessToken;
        const idToken = await user.getIdToken(true);

        if (!_.isEmpty(idToken)) {
          postAuthFirebase && postAuthFirebase({ idToken: idToken });
          this.setState({ method: 'Facebook' });
        }
        this.setState({ facebookLoginLoading: false });

        // ...
      })
      .catch(error => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = FacebookAuthProvider.credentialFromError(error);
        this.setState({ facebookLoginLoading: false });
        Toast.error(Helpers.firebaseSignupErrorCode(errorCode));

        // ...
      });
  };


    // signInWithFacebook = () => {
    //     const { postAuthFirebase } = this.props;
    //
    //     this.setState({
    //         facebookLoginLoading: true,
    //         loginProvider: Constants.LOGIN_PROVIDER_FACEBOOK
    //     });
    //
    //     FB.getLoginStatus(function(response) {
    //         console.log(response);
    //     });
    //
    //     // FB.login(response => {
    //     //     if (response.authResponse) {
    //     //         const accessToken = response.authResponse.accessToken;
    //     //
    //     //         // Use the access token to get user information or to authenticate with your backend
    //     //         FB.api('/me', {fields: 'name,email'}, userInfo => {
    //     //             const user = userInfo;
    //     //             this.setState({ method: 'Facebook', facebookLoginLoading: false });
    //     //         });
    //     //
    //     //     } else {
    //     //         // User cancelled login or did not fully authorize
    //     //         this.setState({ facebookLoginLoading: false });
    //     //         Toast.error("User cancelled login or did not fully authorize.");
    //     //     }
    //     // }, {scope: 'public_profile,email'});
    // };

  onClickForgotPassword = () => {
    Router.push("forgot-password-phone");
  };

  onClickSignInWithAgent = () => {
      sessionStorage.setItem('prevUrlLogin', window.location.pathname);
      setTimeout(() => {
          sessionStorage.removeItem('prevUrlNapic');
      }, 60000);
      Router.push("agent-sign-in");
  };

  render() {
    const { authFirebaseLoading, signInLoading, logoTransparent } = this.props;
    const {
      passwordVisible,
      loginProvider,
      googleLoginLoading,
      email,
      password,
      emailLoading,
      facebookLoginLoading
    } = this.state;
    return (
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          minHeight: "100vh",
          background: "white"
        }}
      >
        {/*====SEO LOGIN=====*/}
        <NextSeo
          defaultTitle="Property Genie | Login"
          title="Property Genie | Login"
          description="Welcome to PropertyGenie. Sign in or join to capitalize on your PropertyGenie experience. Continue. Or. Go on with Google. Go on with Apple."
          canonical="https://www.propertygenie.com.my/login"
          openGraph={{
            url: "https://www.propertygenie.com.my/login",
            title: "Property Genie | Login",
            description:
              "Welcome to Property Genie. Sign in or join to capitalize on your PropertyGenie experience. Continue. Or. Go on with Google. Go on with Apple.",
            images: [
              {
                url: "https://www.propertygenie.com.my/logo-square-default.jpg",
                width: 500,
                height: 500,
                alt: "Property Genie",
                type: "image/jpeg",
                secureUrl:
                  "https://www.propertygenie.com.my/logo-square-default.jpg"
              }
            ],
            siteName: "Property Genie | Login",
            type: "website",
            locale: "en_IE"
          }}
          twitter={{
            handle: "@property_genie",
            site: "@site",
            cardType: "summary_large_image"
          }}
          // robotsProps={{
          //   nosnippet: true,
          //   notranslate: true,
          //   noimageindex: true,
          //   noarchive: true,
          //   maxSnippet: -1,
          //   maxImagePreview: "none",
          //   maxVideoPreview: -1
          // }}
          facebook={{ appId: 1397160940782930 }}
        />
        {/*======End of SEO Sections=======*/}
        {/*=========Header part section=========*/}
        <Header
          key="login"
          brand={true}
          rightLinks={<HeaderLinks />}
          leftLinks={<HeaderLeftLinks />}
          fixed
          color="white"
          style={{ marginBottom: 0, boxShadow: "none" }}
        />
        <Header
          key="login-1"
          brand={true}
          rightLinks={<HeaderLinks />}
          leftLinks={<HeaderLeftLinks />}
          color="white"
          style={{
            marginBottom: 0,
            boxShadow: "none",
            position: "unset",
            zIndex: 1,
            visibility: "hidden"
          }}
        />

        {/*=========End of Header part section=========*/}
        <StyledContainer style={{ backgroundColor: Colors.backgroundPrimary }}>
          <Container>
            {/*<CustomFont*/}
            {/*  size="xalarge"*/}
            {/*  variant="h1"*/}
            {/*  styles={{*/}
            {/*    fontWeight: "600",*/}
            {/*    color: Colors.blackColor,*/}
            {/*    marginTop: "3rem"*/}
            {/*  }}*/}
            {/*>*/}
            {/*  Welcome to Property Genie*/}
            {/*</CustomFont>*/}
            <Grid container>
              <Grid item md={3} />
              {/*<Grid item md={1} />*/}
              <Grid item md={6} xs={12}>
                <StyledLoginContainer>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center"
                    }}
                  >
                    <SignUpLogoContainer>
                      <Image
                        src="/images/Logo/pg-logo-mobile-r.png"
                        layout="fill"
                        objectFit="contain"
                      />
                    </SignUpLogoContainer>
                    <SignUpTitleContainer>
                      <CustomFont
                        variant="h1"
                        styles={{
                          fontWeight: "600",
                          color: Colors.blackColor,
                          marginTop: "1rem"
                        }}
                      >
                        Welcome to PropertyGenie
                      </CustomFont>
                    </SignUpTitleContainer>
                    <SignUpSubContainer style={{ maxWidth: "300px" }}>
                      <CustomFont
                        variant="h1"
                        styles={{
                          color: Colors.blackColor,
                          marginTop: "1rem",
                          marginBottom: "2rem"
                        }}
                      >
                        Login or Sign up to maximize your PropertyGenie
                        experience
                      </CustomFont>
                    </SignUpSubContainer>
                  </div>
                  <StyledLoginButtonContainer
                    variant="outlined"
                    color="inherit"
                    style={{
                      fontSize: "1rem",
                      color: Colors.blackColor,
                      fontWeight: "600",
                      textTransform: "unset",
                      textAlign: "center"
                    }}
                    onClick={this.signInWithFacebook}
                    startIcon={
                      <FacebookIcon
                        style={{
                          // color: "blue",
                          color: Colors.facebookColor,
                          fontSize: "30px",
                          marginRight: 10,
                          minHeight: "35px"
                        }}
                      />
                    }
                    disabled={authFirebaseLoading || googleLoginLoading}
                  >
                    Continue With Facebook
                    {facebookLoginLoading ||
                    (authFirebaseLoading &&
                      _.isEqual(
                        loginProvider,
                        Constants.LOGIN_PROVIDER_FACEBOOK
                      )) ? (
                      <LoadingOverlay hideFont overlay height={30} width={30} />
                    ) : (
                      false
                    )}
                  </StyledLoginButtonContainer>
                  <StyledLoginButtonContainer
                    style={{
                      fontSize: "1rem",
                      color: "#2c2c2c",
                      fontWeight: "600",
                      textTransform: "unset",
                      textAlign: "center",
                      marginTop: "15px"
                    }}
                    onClick={this.onClickLoginWithGoogle}
                    variant="outlined"
                    color="inherit"
                    disabled={authFirebaseLoading || googleLoginLoading}
                    startIcon={
                      <div
                        style={{
                          marginRight: 10,
                          background: "transparent",
                          borderRadius: 2,
                            display: "flex",
                            minHeight: "35px",
                            alignItems: "center"
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 30 30"
                          width="30"
                          height="30"
                        >
                          <g transform="matrix(1, 0, 0, 1, 27.009001, -39.238998)">
                            <path
                              fill="#4285F4"
                              d="M -3.264 51.509 C -3.264 50.719 -3.334 49.969 -3.454 49.239 L -14.754 49.239 L -14.754 53.749 L -8.284 53.749 C -8.574 55.229 -9.424 56.479 -10.684 57.329 L -10.684 60.329 L -6.824 60.329 C -4.564 58.239 -3.264 55.159 -3.264 51.509 Z"
                            />
                            <path
                              fill="#34A853"
                              d="M -14.754 63.239 C -11.514 63.239 -8.804 62.159 -6.824 60.329 L -10.684 57.329 C -11.764 58.049 -13.134 58.489 -14.754 58.489 C -17.884 58.489 -20.534 56.379 -21.484 53.529 L -25.464 53.529 L -25.464 56.619 C -23.494 60.539 -19.444 63.239 -14.754 63.239 Z"
                            />
                            <path
                              fill="#FBBC05"
                              d="M -21.484 53.529 C -21.734 52.809 -21.864 52.039 -21.864 51.239 C -21.864 50.439 -21.724 49.669 -21.484 48.949 L -21.484 45.859 L -25.464 45.859 C -26.284 47.479 -26.754 49.299 -26.754 51.239 C -26.754 53.179 -26.284 54.999 -25.464 56.619 L -21.484 53.529 Z"
                            />
                            <path
                              fill="#EA4335"
                              d="M -14.754 43.989 C -12.984 43.989 -11.404 44.599 -10.154 45.789 L -6.734 42.369 C -8.804 40.429 -11.514 39.239 -14.754 39.239 C -19.444 39.239 -23.494 41.939 -25.464 45.859 L -21.484 48.949 C -20.534 46.099 -17.884 43.989 -14.754 43.989 Z"
                            />
                          </g>
                        </svg>

                      </div>
                    }
                  >
                    Continue With Google
                    {googleLoginLoading ||
                    (authFirebaseLoading &&
                      _.isEqual(
                        loginProvider,
                        Constants.LOGIN_PROVIDER_GOOGLE
                      )) ? (
                      <LoadingOverlay hideFont overlay height={30} width={30} />
                    ) : (
                      false
                    )}
                  </StyledLoginButtonContainer>
                  <Divider
                    style={{
                      marginTop: "2rem",
                      marginBottom: "2rem",
                      height: "fit-content",
                      background: Colors.transparent
                    }}
                  >
                    {/*<CustomFont size="small" styles={{ color: "gray" }}>*/}
                    {/*  or with email*/}
                    {/*</CustomFont>*/}
                    <CustomFont size="small" styles={{ color: "gray" }}>
                      or log in with phone number
                    </CustomFont>
                  </Divider>
                  <StyledInputContainer
                    placeholder="Phone Number"
                    value={email}
                    onChange={this.onChangeEmailText}
                    inputProps={ariaLabel}
                  />
                  <StyledInputContainer
                    placeholder="Password"
                    type={passwordVisible ? "text" : "password"}
                    inputProps={ariaLabel}
                    value={password}
                    onChange={this.onChangePasswordText}
                    style={{ marginTop: "1.5rem" }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={this.handlePasswordVisible}
                          onMouseDown={this.handlePasswordVisible}
                        >
                          {!passwordVisible ? (
                            <VisibilityOff
                              style={{ color: Colors.blackColor }}
                            />
                          ) : (
                            <Visibility style={{ color: Colors.blackColor }} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <StyledForgotPasswordContainer>
                    <StyledForgotPasswordItemContainer>
                      <a
                        href={`${process.env.WEBSITE_URL}forgot-password-phone`}
                        onClick={e => this.onClickForgotPassword}
                      >
                        <CustomFont
                          size="small"
                          styles={{
                            color: Colors.primaryColor,
                            fontWeight: "600",
                            marginRight: "10px"
                          }}
                        >
                          Forgot password?
                        </CustomFont>
                      </a>
                    </StyledForgotPasswordItemContainer>
                  </StyledForgotPasswordContainer>
                  <StyledLoginEmailButtonContainer
                    style={{
                      fontSize: "1rem",
                      color: "white",
                      fontWeight: "600",
                      textTransform: "unset",
                      textAlign: "center",
                      marginTop: 15,
                      background: Colors.primaryColor
                    }}
                    variant="contained"
                    // color="primary"
                    onClick={this.onClickLoginWithEmail}
                    disabled={
                      authFirebaseLoading ||
                      googleLoginLoading ||
                      facebookLoginLoading ||
                      signInLoading
                    }
                  >
                    Log In
                  </StyledLoginEmailButtonContainer>
                  <SignUpSubContainer>
                  <CustomFont
                    size="normal"
                    styles={{ fontWeight: "600", marginTop: "2rem" }}
                  >
                    {`Don't have an account? `}
                    <a href={`${process.env.WEBSITE_URL}sign-up-form`}>
                      <span
                        onClick={this.onClickSignUp}
                        style={{
                          color: Colors.primaryColor,
                          textDecorationLine: "underline",
                          fontWeight: "600",
                          cursor: "pointer"
                        }}
                      >
                        Sign up now.
                      </span>
                    </a>
                  </CustomFont>
                  </SignUpSubContainer>
                <SignUpSubContainer>
                  <CustomFont
                    size="normal"
                    styles={{ fontWeight: "600", marginTop: "1.5rem" }}
                  >
                    {`Are you an agent? `}
                    <a href={`${process.env.WEBSITE_URL}agent-sign-in`}>
                      <span
                        onClick={this.onClickSignInWithAgent}
                        style={{
                          color: Colors.primaryColor,
                          textDecorationLine: "underline",
                          fontWeight: "600",
                          cursor: "pointer"
                        }}
                      >
                        Sign in here.
                      </span>
                    </a>
                  </CustomFont>
                </SignUpSubContainer>
                  {signInLoading || emailLoading || authFirebaseLoading ? (
                    <LoadingOverlay overlay />
                  ) : (
                    false
                  )}
                </StyledLoginContainer>
              </Grid>
              <Grid item md={3} />
            </Grid>
          </Container>
        </StyledContainer>
        {/*=========Footer part section=========*/}
        <Footer />
        {/*=========End of Footer part section=========*/}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    authFirebaseLoading: selectors.getAuthFirebaseLoading(state),
    authFirebaseStatus: selectors.getAuthFirebaseStatus(state),
    authToken: selectors.getAuthToken(state),
    signInLoading: selectors.getAuthSignInLoading(state),
    signInStatus: selectors.getAuthSignInStatus(state),
    meData: selectors.getProfileData(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    postAuthFirebase: postData =>
      dispatch(authActions.postAuthFirebaseRequest(postData)),
    postAuthSignIn: postData =>
      dispatch(authActions.postAuthSignInRequest(postData))
  };
}

export default withTheme(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Login)
);
